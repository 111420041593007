/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  blogs: [],
  teams: [],
  customers: [],
};

export const createTeam = createAsyncThunk(
  'blog/createTeam',
  async (team, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/teams`,
        { team },
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const teams = await response.data;
      return teams;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getComments = createAsyncThunk(
  'blog/getComments',
  async (params, rejectWithValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/comments/${params.id}?page=${params.page}&per_page=${params.perPage}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const comments = await response.data;
      return comments;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createComment = createAsyncThunk(
  'blog/createComment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/comments`,
        data,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const comment = await response.data;
      return comment;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const editComment = createAsyncThunk(
  'blog/editComment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/comments/${data.id}`,
        data,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const comment = await response.data;
      return comment;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteComment = createAsyncThunk(
  'blog/deleteComment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/comments/${data.id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const comment = await response.data;
      return comment;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getBlogs = createAsyncThunk(
  'blog/getBlogs',
  async (params, rejectWithValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/blog/show?order_by=${params.orderBy}&search=${params.search}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const blogs = await response.data;
      return blogs;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getBlog = createAsyncThunk(
  'blog/getBlog',
  async (id, rejectWithValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/blog/get_account?id=${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const blog = await response.data;
      return blog;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getTeams = createAsyncThunk(
  'blog/getTeams',
  async (params, rejectWithValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/teams?order_by=${params.orderBy}&search=${params.search}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      console.log('teams');
      const teams = await response.data;
      
      return teams;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getCustomers = createAsyncThunk(
  'blog/getCustomers',
  async (rejectWithValue) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/get_customers`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const customers = await response.data;
      return customers;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createBlog = createAsyncThunk(
  'blog/createBlog',
  async (blog, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/blog`,
        blog, // esto se convierte en data
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const blogs = await response.data;

      return blogs;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateBlogTeams = createAsyncThunk(
  'blog/updateBlogTeam',
  async (blog, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/blog/update_teams`,
        { blog },
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const teams = await response.data;
      return teams;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateBlog = createAsyncThunk(
  'blog/updateBlog',
  async (blog, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/blog/${blog.id}`,
        { blog },
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const blogs = await response.data;
      return blogs;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteBlog = createAsyncThunk(
  'blog/deleteBlog',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/blog/${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const blogs = await response.data;
      return blogs;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteTeam = createAsyncThunk(
  'blog/deleteTeam',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/teams/${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const teams = await response.data;
      return teams;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateTeam = createAsyncThunk(
  'blog/updateTeam',
  async (team, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/teams/${team.id}`,
        { team }, // esto se convierte en data
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const teams = await response.data;
      return teams;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updateUserFromZoho = createAsyncThunk(
  'blog/updateUserFromZoho',
  async (user, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/blog/update_customer_from_zoho`,
        { user }, // esto se convierte en data
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const users = await response.data;
      return users;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendResumenAccount = createAsyncThunk(
  'blog/sendResumenAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/blog/send_resumen_account`,
        {
          id: data.id,
          copy_team: data.copyTeam,
          start_date: data.start_date,
          end_date: data.end_date,
          preview: data.preview,
        },
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const info = await response.data;
      return info;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getPlannings = createAsyncThunk(
  'planning/getPlannings',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        // `${process.env.REACT_APP_API_URL}/v1/planning?order_by=${params.orderBy}&search=${params.search}`,
        `${process.env.REACT_APP_API_URL}/v1/plannings?page=${params.page}&search=${params.search}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const planning = await response.data;
      return planning;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getBlogPlannings = createAsyncThunk(
  'planning/getBlogPlannings',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/plannings/get_blog_plannings?blog_id=${params.blogId}&page=${params.page}&search=${params.search}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const planning = await response.data;
      return planning;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getPlanning = createAsyncThunk(
  'planning/getPlanning',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/plannings/${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const planning = await response.data;
      return planning;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getPlanningPosts = createAsyncThunk(
  'planning/getPlanningPosts',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/plannings/get_posts?id=${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const posts = await response.data;
      return posts;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const postPlanning = createAsyncThunk(
  'planning/postPlanning',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/plannings/publish_planning_posts`,
        { id },
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const planning = await response.data;
      return planning;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createPost = createAsyncThunk(
  'planning/createPost',
  async (post, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/posts`,
        post,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const postData = await response.data;
      return postData;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const updatePost = createAsyncThunk(
  'planning/updatePost',
  async ({ post, id }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/posts/${id}`,
        post,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const postData = await response.data;
      return postData;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deletePost = createAsyncThunk(
  'planning/deletePost',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/posts/${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const postData = await response.data;
      return postData;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createPlanning = createAsyncThunk(
  'planning/createPlanning',
  async (planningData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/plannings`,
        { planning: planningData },
        {
          headers: {
            'Content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createPostWithIa = createAsyncThunk(
  'planning/createPostWithIa',
  async (post, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/posts/create_post_with_ia`,
        post,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const postData = await response.data;
      return postData;
    } catch (error) {
      console.log('response', error);
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteConfigAccount = createAsyncThunk(
  'blog/deleteConfigAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/blog/delete_config?id=${data.id}`,
        { config_key: data.configKey },
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createTaskToAccount = createAsyncThunk(
  'blog/createTaskToAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/tasks`,
        data,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getTasks = createAsyncThunk(
  'blog/getTasks',
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/tasks`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTask = createAsyncThunk(
  'blog/updateTask',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/tasks/${data.id}`,
        data,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateTaskToAccount = createAsyncThunk(
  'blog/updateTaskToAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/tasks/${data.id}`,
        data,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
    setBlogs: (state, action) => {
      state.blogs = action.payload;
    },
  },
  extraReducers: {
    [getBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload;
    },
    [getTeams.fulfilled]: (state, action) => {
      state.teams = action.payload;
    },
    [getCustomers.fulfilled]: (state, action) => {
      state.customers = action.payload;
    },
    [updateUserFromZoho.fulfilled]: (state, action) => {
      state.customers = action.payload.customers;
    },
    [createBlog.fulfilled]: (state, action) => {
      state.blogs.blogs.unshift(action.payload.blogs);
    },
    // [updateBlog.fulfilled]: (state, action) => {
    //   state.blogs.blogs = state.blogs.blogs.map((blog) => {
    //     if (blog.id === action.payload.blog.id) {
    //       return action.payload.blog;
    //     }
    //     return blog;
    //   });
    // },
    [deleteBlog.fulfilled]: (state, action) => {
      state.blogs.blogs = state.blogs.blogs.filter(
        (blog) => blog.id !== action.payload.blog.id
      );
    },
    [createTeam.fulfilled]: (state, action) => {
      state.teams.teams.unshift(action.payload.team);
    },
    [deleteTeam.fulfilled]: (state, action) => {
      state.teams.teams = state.teams.teams.filter(
        (team) => team.id !== action.payload.team.id
      );
    },
    [updateTeam.fulfilled]: (state, action) => {
      state.teams.teams = state.teams.teams.map((team) => {
        if (team.id === action.payload.team.id) {
          return action.payload.team;
        }
        return team;
      });
    },
  },
});

export const { setBlogs } = blogSlice.actions;

export default blogSlice.reducer;
